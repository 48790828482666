import { ReactNode } from "react";

export interface ConfirmationDialogProps {
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  title: string;
  message?: ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  icon?: string;
  hasCancelButton?: boolean;
  labelConfirm?: string;
  labelCancel?: string;
  disabled?: boolean;
  loading?: boolean;
  kind?: ConfirmationDialogKind;
  className?: string;
  description?: string;
}

export enum ConfirmationDialogKind {
  information = "information",
  danger = "danger",
}
