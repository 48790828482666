import { tv } from "tailwind-variants";
import { cn } from "~/utils/cn";
import { StatusTagColors } from "./status-tag.types";

export const statusTagStyle = tv({
  slots: {
    tag: "flex h-8 w-fit select-none flex-nowrap rounded-full border border-neutral-10 bg-white px-3 py-1",
    contentContainer: "flex items-center gap-2",
    textContent: "text-sm/6 font-medium",
    statusIcon: "h-2 w-2 rounded-full",
  },
  variants: {
    small: {
      true: {
        tag: "px-2",
      },
    },
    status: {
      [StatusTagColors.green]: { statusIcon: "bg-status-success" },
      [StatusTagColors.red]: { statusIcon: "bg-status-critical" },
      [StatusTagColors.yellow]: { statusIcon: "bg-status-warning" },
      [StatusTagColors.gray]: { statusIcon: "bg-neutral-secondary" },
      [StatusTagColors.disabled]: { statusIcon: "bg-icon-neutral-disabled" },
    },
  },
});
