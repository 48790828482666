import { tv } from "tailwind-variants";
import { SelectVariant } from "../../select.types";

export const buttonClassName = tv({
  base: "flex w-full items-center justify-between gap-2",
  variants: {
    variant: {
      [SelectVariant.default]: "",
      [SelectVariant.inline]: "",
      [SelectVariant.filter]:
        "w-auto border-transparent bg-transparent data-[is-open=true]:bg-neutral-20",
    },
  },
});

export const selectedContentContainerClassName = tv({
  base: "flex w-full flex-wrap items-center gap-1 text-sm leading-6",
  variants: {
    variant: {
      [SelectVariant.default]: "",
      [SelectVariant.inline]: "place-content-end",
      [SelectVariant.filter]: "text-sm/6 font-medium text-neutral-primary",
    },
  },
});
