import { cn } from "~/utils/cn";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "~/scalis-components/core/command";
import { Divider } from "~/scalis-components/core/divider";
import { ScrollArea } from "~/scalis-components/core/scroll-area";
import { EmptyList, OptionIcon } from "..";
import { PopoverContentProps } from "./popover-content.types";

export const PopoverContent = ({
  searchPlaceholder,
  emptyTerm,
  scrollHeight,
  options,
  multi,
  value,
  searchByLabel,
  onChange,
  handleSelect = () => {},
  startAdornment,
}: PopoverContentProps) => {
  return (
    <Command>
      {searchPlaceholder && (
        <>
          <CommandInput placeholder={searchPlaceholder} className="h-9" />
          <Divider />
          <CommandEmpty>
            <EmptyList emptyTerm={emptyTerm} />
          </CommandEmpty>
        </>
      )}

      <ScrollArea
        className={`max-h-56 overflow-auto p-2 ${scrollHeight ? `h-[${scrollHeight}]` : ""}`}
      >
        {!options?.length && <EmptyList emptyTerm={emptyTerm} />}
        <CommandGroup className="overflow-none">
          {options?.map(option => {
            const isChecked = multi
              ? value?.includes(option.value)
              : option.value === value;
            return (
              <CommandItem
                value={searchByLabel ? option.label : option.value}
                key={option.value}
                onSelect={() => {
                  onChange
                    ? onChange(option.value)
                    : handleSelect(option.value);
                }}
                disabled={option.disabled}
                className={cn(
                  "my-[2px] line-clamp-1 flex cursor-pointer gap-2 rounded-lg p-2 text-sm  hover:bg-button-subtle-hover",
                  { "!bg-button-subtle-pressed": isChecked },
                  { "justify-between": !multi },
                )}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                {multi && (
                  <div
                    className={cn(
                      "flex size-4 items-center justify-center rounded border border-solid border-bg-neutral-10",
                      isChecked && "border-none bg-neutral-primary",
                    )}
                  >
                    {isChecked && (
                      <i className="fa-solid fa-check fa-xs pt-0.5 text-neutral-on-brand" />
                    )}
                  </div>
                )}

                <span className="flex gap-2 text-sm leading-6">
                  <OptionIcon
                    icon={option.icon}
                    startAdornment={startAdornment}
                  />

                  {option.label}
                </span>
                {option.disabled ? (
                  <div>{option.disabledLabel}</div>
                ) : (
                  !multi && isChecked && <i className="fa-regular fa-check"></i>
                )}
              </CommandItem>
            );
          })}
        </CommandGroup>
      </ScrollArea>
    </Command>
  );
};
