import React from "react";

import { cn } from "~/utils/cn";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTrigger,
  DialogHeader,
} from "../dialog";
import { Button, ButtonKind, ButtonStandaloneKind } from "../../button";
import { ComposableDialogProps } from "./composable-dialog.types";
import { Icon } from "../../icon";
import { DialogDescription, DialogTitle } from "@radix-ui/react-dialog";

export const ComposableDialog: React.FC<ComposableDialogProps> = ({
  isOpen,
  title,
  onCancel,
  onOpenChange,
  children,
  className,
  contentClassName,
  successButtonProps,
  standaloneButtonProps,
  cancelButtonProps,
  description,
  hideCancelButton = false,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent
        className={cn("flex flex-col items-center gap-0 rounded-xl", className)}
      >
        <DialogHeader className="items-center">
          <DialogClose asChild data-testid="close-dialog-button">
            <Icon icon="fa-solid fa-xmark cursor-pointer text-icon-neutral-20" />
          </DialogClose>

          <div className="flex flex-col tracking-tighter">
            <DialogTitle className="text-lg/6 font-medium text-neutral-primary">
              {title}
            </DialogTitle>
            {!!description && (
              <DialogDescription className="text-sm text-neutral-secondary">
                {description}
              </DialogDescription>
            )}
          </div>
        </DialogHeader>

        <div
          className={cn(
            "max-h-[70vh] w-full overflow-y-auto p-4",
            contentClassName,
          )}
        >
          {children}
        </div>
        <DialogFooter>
          <div>
            {!!standaloneButtonProps && (
              <Button
                {...standaloneButtonProps}
                isStandalone={true}
                kind={ButtonStandaloneKind.brand}
              >
                {standaloneButtonProps.label}
              </Button>
            )}
          </div>
          <div className="flex gap-2">
            {!hideCancelButton && (
              <>
                {cancelButtonProps?.onClick ? (
                  <Button
                    kind={ButtonKind.secondary}
                    data-testid="dialog-cancel-button"
                    loading={cancelButtonProps?.loading}
                    disabled={cancelButtonProps?.disabled}
                    onClick={cancelButtonProps.onClick}
                  >
                    {cancelButtonProps?.label ?? "Cancel"}
                  </Button>
                ) : (
                  <DialogTrigger asChild>
                    <Button
                      kind={ButtonKind.secondary}
                      data-testid="dialog-cancel-button"
                      loading={cancelButtonProps?.loading}
                      disabled={cancelButtonProps?.disabled}
                      onClick={onCancel}
                    >
                      {cancelButtonProps?.label ?? "Cancel"}
                    </Button>
                  </DialogTrigger>
                )}
              </>
            )}
            {successButtonProps && (
              <Button {...successButtonProps}>
                {successButtonProps.label}
              </Button>
            )}
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
