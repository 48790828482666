import { ReactNode } from "react";

export interface StatusTagProps extends React.HTMLAttributes<HTMLDivElement> {
  text: ReactNode;
  status?: StatusTagColors;
  customColor?: string;
  contentContainerClassName?: string;
  statusIconClassName?: string;
  small?: boolean;
}

export enum StatusTagColors {
  green = "green",
  red = "red",
  yellow = "yellow",
  gray = "gray",
  disabled = "disabled",
  custom = "custom",
}

export type StatusStyleOption =
  | StatusTagColors.green
  | StatusTagColors.red
  | StatusTagColors.yellow
  | StatusTagColors.gray
  | StatusTagColors.disabled
  | undefined;
