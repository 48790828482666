import { tv } from "tailwind-variants";
import { cn } from "~/utils/cn";
import { SelectKind, SelectSize, SelectVariant } from "./select.types";

export const selectClassName = tv({
  base: cn(
    "bg-neutral-00 hover:bg-neutral-10",
    "border border-solid rounded-xl",
    "text-neutral-tertiary font-normal text-sm/6",
    "px-3 gap-2 focus:border-brand-primary-rest",
  ),
  variants: {
    kind: {
      [SelectKind.default]: "border-bg-neutral-10",
      [SelectKind.error]: "border-status-critical text-neutral-primary",
      [SelectKind.filled]: "text-neutral-primary",
      [SelectKind.disabled]:
        "text-neutral-disabled border-neutral-disabled bg-neutral-00",
    },
    size: {
      [SelectSize.large]: "py-2 h-10",
      [SelectSize.medium]: "py-1.5",
      [SelectSize.small]: "py-1 h-8",
      [SelectSize.variable]: "py-2 h-auto min-h-10",
    },
    variant: {
      [SelectVariant.default]: "",
      [SelectVariant.inline]:
        "border-none bg-transparent hover:bg-transparent p-0 min-w-[200px]",
      [SelectVariant.filter]: "",
    },
  },
  compoundVariants: [
    {
      loading: false,
      className: "hover:bg-neutral-10 focus:border-brand-primary-rest",
    },
    {
      variant: SelectVariant.inline,
      className: "focus:border-transparent",
    },
  ],
});

export const iconClassName = tv({
  base: "fa-light fa-chevron-down text-sm",
  variants: {
    variant: {
      [SelectVariant.default]: "",
      [SelectVariant.inline]: "",
      [SelectVariant.filter]:
        "text-icon-neutral-20 transition-transform data-[is-open=true]:-rotate-180",
    },
  },
});

export const containerBaseClassName = tv({
  base: "w-full",
  variants: {
    variant: {
      [SelectVariant.default]: "",
      [SelectVariant.inline]: "inline-flex items-center",
      [SelectVariant.filter]: "w-auto inline-flex items-center",
    },
  },
});

export const innerContainerBaseClassName = tv({
  base: "flex w-full flex-col text-neutral-primary",
  variants: {
    variant: {
      [SelectVariant.default]: "",
      [SelectVariant.inline]: "flex-row items-center gap-2",
      [SelectVariant.filter]: "flex-row items-center gap-2",
    },
  },
});

export const labelClassName = tv({
  base: "text-sm/6 font-normal text-neutral-primary",
  variants: {
    variant: {
      [SelectVariant.default]: "",
      [SelectVariant.inline]: "flex items-center whitespace-nowrap",
      [SelectVariant.filter]: "flex items-center whitespace-nowrap",
    },
  },
});

export const dropListContainerClassName = tv({
  base: "relative",
  variants: {
    variant: {
      [SelectVariant.default]: "",
      [SelectVariant.inline]: "flex-grow",
      [SelectVariant.filter]: "w-max flex-grow",
    },
  },
});

export const dropListButtonClassName = tv({
  variants: {
    variant: {
      [SelectVariant.default]: "",
      [SelectVariant.inline]: "",
      [SelectVariant.filter]: "!border-transparent",
    },
  },
});
