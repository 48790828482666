import { tv } from "tailwind-variants";

export const tagStyle = tv({
  slots: {
    tag: "flex h-8 w-fit select-none flex-nowrap items-center gap-2 rounded-full px-3",
    contentContainer: "flex items-baseline gap-2",
    icons: "fa-sm",
    textContent: "text-sm font-medium tracking-tighter",
    closeIcon: "fa-solid fa-xmark fa-sm cursor-pointer text-icon-neutral-20",
  },
  variants: {
    color: {
      neutral: { tag: "bg-neutral-20" },
      "cherry-red": { tag: "bg-dataviz-cherry-red-10" },
      "soft-orange": { tag: "bg-dataviz-soft-orange-10" },
      "yolk-yellow": { tag: "bg-dataviz-yolk-yellow-10" },
      "neon-yellow": { tag: "bg-dataviz-neon-yellow-10" },
      "lime-green": { tag: "bg-dataviz-lime-green-10" },
      "moss-green": { tag: "bg-dataviz-moss-green-10" },
      "leaf-green": { tag: "bg-dataviz-leaf-green-10" },
    },
    small: {
      true: {
        tag: "h-6 px-2",
        contentContainer: "gap-1 text-xs tracking-[-0.3px]",
        icons: "fa-xs",
        textContent: "text-xs",
        closeIcon: "fa-xs",
      },
    },
  },
});
